import { MailIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import Modal, { ModalSizes } from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { Contact } from '../../types/Contact.type';
import utc from 'dayjs/plugin/utc';
import { MeContext } from '../../common/contexts/me.context';
dayjs.extend(utc);

type Props = {
	close: () => void;
	contact: Contact;
};

const ContactModal: React.FC<Props> = ({ close, contact }) => {
	const { me } = useContext(MeContext);

	return (
		<Modal
			state={!!contact ? OpenClosedStates.Open : OpenClosedStates.Closed}
			size={ModalSizes['xl']}
			noScroll
		>
			<div>
				<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
					<MailIcon className="h-6 w-6 text-gray-600 dark:text-gray-400" />
				</div>
				<div className="mt-3 sm:mt-5 text-left">
					<h3
						className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 text-center"
						id="modal-headline"
					>
						Sent{' '}
						<span className="font-light">
							{dayjs.utc(contact?.createDate).local().format('MM-DD-YYYY')} at{' '}
							{dayjs.utc(contact?.createDate).local().format('hh:mma')}
						</span>
					</h3>
					<div className="mt-6">
						<dl className="w-full space-y-4">
							{!!contact?.product && (
								<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
									<div>
										<dt className="text-lg leading-6 font-medium text-gray-900">
											Regarding product
										</dt>
										<dd className="text-sm text-gray-500">
											{me.role === 'superAdmin' ? (
												<a
													href={`${process.env.REACT_APP_CUSTOMER_URL}/products/${contact.productPath}`}
													target="_blank"
													rel="noreferrer"
													className="text-primary-500 hover:text-primary-600"
												>
													{contact?.productName}
												</a>
											) : (
												<Link
													to={`/products/${contact.product}`}
													className="text-primary-500 hover:text-primary-600"
												>
													{contact?.productName}
												</Link>
											)}
										</dd>
									</div>
								</dl>
							)}
							{contact?.name && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Name
									</dt>
									<dd className="text-sm text-gray-500">{contact?.name}</dd>
								</div>
							)}
							{contact?.email && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Email
									</dt>
									<dd className="text-sm text-primary-500">
										<a href={`mailto:${contact?.email}`}>{contact?.email}</a>
									</dd>
								</div>
							)}
							{contact?.phone && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Phone
									</dt>
									<dd className="text-sm text-primary-500">
										<a href={`tel:${contact?.phone}`}>{contact?.phone}</a>
									</dd>
								</div>
							)}
							{contact?.message && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Message
									</dt>
									<dd className="text-sm text-gray-500">{contact?.message}</dd>
								</div>
							)}
							{contact?.zip && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Near Zip Code
									</dt>
									<dd className="text-sm text-gray-500">{contact?.zip}</dd>
								</div>
							)}
							{contact?.contacted && (
								<div>
									<dt className="text-md leading-6 font-medium text-primary-500">
										Contacted
									</dt>
									<dd className="text-sm text-gray-500">
										Contacted by{' '}
										<span className="font-bold">{contact?.contacted}</span>{' '}
										{contact?.contactedDate && (
											<span>
												on{' '}
												<span className="font-bold">
													{dayjs(contact?.contactedDate).format('MM-DD-YYYY')}
												</span>
											</span>
										)}
									</dd>
								</div>
							)}
						</dl>
					</div>
				</div>
				<div className="mt-6 grid grid-cols-1 gap-3 grid-flow-row-dense">
					<Button
						text="Close"
						onClick={close}
						fullWidth
						color={ButtonColors.plain}
						className="border border-gray-300 dark:border-darkGray-500"
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ContactModal;
